<template>
  <div>
    <div class="flex flex-col md:flex-row gap-2" v-loading="isLoading">
      <div class>
        <div class="d-flex">
          <label for class="mb-1 whitespace-nowrap font-semibold">{{ $t(" Mã bệnh (ICD 10)") }}</label>
        </div>
        <el-select
          v-model="diseasesCode"
          filterable
          remote
          :disabled="disabled"
          :remote-method="(code) => getIcd10(code)"
          placeholder="Chọn mã"
          @change="setName"
        >
          <el-option
            v-for="item in diseasesList"
            :key="item.id"
            :label="item.code"
            :value="item.code"
          ></el-option>
        </el-select>
      </div>
      <div class="flex-1">
        <div class="d-flex">
          <label for class="mb-1 font-semibold">
            {{
            $t("Tên bệnh")
            }}
          </label>
        </div>
        <el-select
          v-model="diseasesName"
          filterable
          allow-create
          :disabled="disabled"
          remote
          :remote-method="(name) => getIcd10(undefined, name)"
          placeholder="Chọn tên"
          @change="setCode"
          class="cs-mobile-width w-full"
        >
          <el-option
            v-for="item in diseasesList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
            class="h-auto"
          >
            <span style="word-break: break-word; white-space: pre-line">
              {{
              item.name
              }}
            </span>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SelectDiseaseICD10V2',
  props: { dataIcd: Object, disabled: Boolean },
  data () {
    return {
      diseasesCode: '',
      diseasesName: '',
      diseasesList: [],
      isLoading: false
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        if (this.dataIcd?.code || this.dataIcd?.name) {
          this.diseasesCode = this.dataIcd.code
          this.diseasesName = this.dataIcd.name
        }
      }
    }
  },
  async created () {
    await this.getIcd10()
    if (this.dataIcd?.code || this.dataIcd?.name) {
      this.diseasesCode = this.dataIcd.code
      this.diseasesName = this.dataIcd.name
    }
  },
  methods: {
    async getIcd10 (code, name) {
      try {
        this.isLoading = true
        const p = {
          limit: 1000,
          code,
          name
        }
        const res = await this.$rf.getRequest('AuthRequest').getIcd10(p)
        this.diseasesList = res?.data?.data || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    setName (value) {
      const item = _.find(this.diseasesList, ['code', value])
      this.diseasesName = item?.name
      this.$emit('onChange', item)
    },
    setCode (value) {
      const item = _.find(this.diseasesList, ['name', value])
      this.diseasesCode = item?.code
      this.$emit('onChange', item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-input {
  .el-input__inner {
    background: #fff;
  }
}
</style>