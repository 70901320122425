<template>
  <div>
    <el-dialog
      :visible.sync="isOpen"
      fullscreen
      :show-close="false"
      class="cs-dialog"
    >
      <div v-loading="isLoading || isRendering">
        <div
          class="cs-sign flex justify-between items-center py-2 px-4 gap-4 mb-4"
        >
          <div class="fs-20 font-bold uppercase text-white">
            {{ getEnvelopeNameByType(documentType) }}
          </div>

          <div class="cs-btn-container">
            <el-button
              class="fs-16"
              size="small"
              plain
              type="primary"
              @click="handleSignDocument"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                />
              </svg>
              {{ $t("Ký") }}
            </el-button>
            <SelectSignature
              v-if="!isPatientSign"
              :signerID="signerID"
              :refreshSignature="refreshSignature"
              @onChange="handleChangeImageSignature"
              @onOpenModalCreate="openModalCreate"
            />
            <el-button
              class="fs-16"
              size="small"
              plain
              type="primary"
              @click="handleClose"
              >{{ $t("Đóng") }}</el-button
            >
          </div>
        </div>
        <div class="text-black">
          <div class="p-2 cursor-none cs-container">
            <div :id="containerID" class="pdf-container cs-cursor">
              <div
                v-for="(signatory, index) in listUserSignatories"
                :key="index"
              >
                <div
                  v-if="
                    signatory && (signatory.isSelected || signatory.metadata)
                  "
                >
                  <img
                    v-if="signatureImage"
                    class="cs-image-pdf"
                    :id="`${imageID}_${signatory.id}`"
                    :src="signatureImage"
                    alt="Moving Image"
                    :width="imageX"
                    :height="imageY"
                  />
                  <div
                    v-else
                    :id="`${imageID}_${signatory.id}_default`"
                    class="cs-default-signature"
                  >
                    <div class="font-bold">{{ $t("Ký") }}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <vue-pdf-embed
                :ref="elementID"
                :id="elementID"
                :source="urlPDF"
                @internal-link-clicked="handleClickedPage"
                @loaded="handleDocumentLoad"
                @rendered="handleDocumentRender"
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <ModalCreateSignature
      ref="ModalCreateSignature"
      @onSuccess="handleCreateSignatureSuccess"
    />
  </div>
</template>
    
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import SignatureRequest from '@/api/request/SignatureRequest'
import SelectSignature from './SelectSignature.vue'
import {
  // ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_TYPE,
  SIGNATORY_ROLE,
  SIGNATORY_STATUS
} from '../../utils/constants'
import uploadS3File from '../../utils/uploadS3File'
import appUtils from '../../utils/appUtils'
import ModalCreateSignature from '../../pages/DoctorSignature/ModalCreateSignature.vue'
import { mixinSignPdf } from '../../utils/mixinSignPdf'

export default {
  name: 'ModalSignPDF',
  components: { VuePdfEmbed, SelectSignature, ModalCreateSignature },
  mixins: [mixinSignPdf],
  props: {
    elementIDProps: {
      type: String,
      required: true
    },
    containerIDProps: {
      type: String,
      required: true
    },
    imageIDProps: {
      type: String,
      required: true
    },
    envelopeName: {
      type: String
    }
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      pageCount: null,
      signatureImage: '',
      isRendering: true,
      imageX: 80,
      imageY: 40,
      urlPDF: '',
      documentPDFViewer: null,
      documentType: null,
      isUseSignProcedure: false,
      // procedures: [],
      signerID: null,
      documentID: null,
      envelopeSignatories: [],
      selectedSignature: {},
      containerID: 'ContainerPDF',
      elementID: 'SignerPDF',
      imageID: 'ImageID',
      procedureID: null,
      envelopeInfo: null,
      refreshSignature: 0,
      canvasEventHandlers: new Map(),
      listUserSignatories: [],
      isPatientSign: false
    }
  },
  computed: {
    request () {
      return new SignatureRequest()
    },
    canSign () {
      const isAllSelectedSignatories = this.listUserSignatories.every(
        (item) =>
          item?.metadata &&
          ((item?.person_id && this.isPatientSign) ||
            item?.user_id === this.$user?.id)
      )
      return isAllSelectedSignatories
    },
    currentSignatory () {
      return this.listUserSignatories?.find((item) => item?.isSelected)
    }
  },
  watch: {
    elementIDProps () {
      this.elementID = this.elementIDProps || 'SignerPDF'
    },
    containerIDProps () {
      this.containerID = this.containerIDProps || 'ContainerPDF'
    },
    imageIDProps () {
      this.imageID = this.imageIDProps || 'imageID'
    }
  },
  created () {
    this.elementID = this.elementIDProps || 'SignerPDF'

    this.containerID = this.containerIDProps || 'ContainerPDF'
    this.imageID = this.imageIDProps || 'imageID'
  },
  mounted () {
    // this.handleCheckMouseInPage()
  },
  methods: {
    handleClose () {
      this.handleClearEvent()

      this.urlPDF = ''
      this.documentPDFViewer = null
      this.envelopeSignatories = []
      this.procedureID = null
      this.signerID = null
      this.documentID = null
      this.documentType = null
      this.isUseSignProcedure = false
      this.envelopeInfo = null
      this.refreshSignature = 0
      this.isOpen = false
      this.listUserSignatories = []
      this.isRendering = false
      this.signatureImage = ''
      this.$emit('onClose')
    },
    handleOpen (
      url,
      docType,
      documentID,
      signerIDProp,
      signatoryProps,
      envelopeInfoProp,
      procedureIDProp,
      isPatientSignProp
    ) {
      this.isRendering = true
      this.urlPDF = url
      this.documentType = docType
      this.documentID = documentID
      this.signerID = signerIDProp
      this.isPatientSign = isPatientSignProp
      this.envelopeInfo = envelopeInfoProp
      this.procedureID = procedureIDProp
      this.isUseSignProcedure = Boolean(procedureIDProp)
      this.envelopeSignatories = signatoryProps || []
      this.canvasEventHandlers = new Map()
      this.handleProcessListUserSignatories(signatoryProps)
      this.isOpen = true
    },
    handleDocumentLoad (documentPDF) {
      this.pageCount = documentPDF.numPages
      this.documentPDFViewer = documentPDF
    },
    handleDocumentRender () {
      if (!this.isRendering) return
      this.handleCheckMouseInPage()
      this.isRendering = false
    },
    handleClickedPage (page) {
      console.log('clicked link', page)
    },
    handleMoveImage (e, container) {
      const self = this

      self.listUserSignatories.forEach((signatory, index) => {
        const imageID = `${self.imageID}_${signatory?.id}`

        const image = document.getElementById(imageID)
        const imageDefault = document.getElementById(`${imageID}_default`)
        if (signatory?.metadata || !signatory?.isSelected) {
          return
        }

        const mouseX = e.clientX - container.getBoundingClientRect().left
        const mouseY = e.clientY - container.getBoundingClientRect().top

        if (image) {
          image.style.left = mouseX + 'px'
          image.style.top = mouseY + 'px'
        }

        if (imageDefault) {
          imageDefault.style.left = mouseX + 'px'
          imageDefault.style.top = mouseY + 'px'
        }
      })
    },
    handleCheckMouseInPage () {
      const container = document.getElementById(this.containerID)
      const canvases = container?.querySelectorAll('canvas')
      const self = this

      const containerHandler = function (e) {
        self.handleMoveImage(e, container)
      }
      container.addEventListener('mousemove', containerHandler)
      self.canvasEventHandlers.set(container, containerHandler)

      canvases.forEach((item, index) => {
        const handler = function (event) {
          if (
            self.currentSignatory?.person_id &&
            self.isPatientSign &&
            !self.signatureImage
          ) {
            self.$refs.ModalCreateSignature.handleOpen(
              self.signerID,
              '',
              self.currentSignatory?.person_id
            )

            return
          }

          self.handleCalcPositionSignature(item, event, index + 1)
        }
        item.addEventListener('mousedown', handler)
        self.canvasEventHandlers.set(item, handler)
      })
    },
    async handleCalcPositionSignature (canvas, event, page) {
      const pageViewer = await this.getPDFPageViewer(page)

      // const pointX = event.offsetX
      // const pointY = event.offsetY
      const imageX = this.imageX
      const imageY = this.imageY

      // Lấy kích thước của canvas
      var rect = canvas.getBoundingClientRect()

      // Tính toán tọa độ x, y của chuột trên canvas
      var x = event.clientX - rect.left
      var y = event.clientY - rect.top

      // Tính toán tọa độ trên PDF từ tọa độ chuột trên canvas
      var viewport = pageViewer.getViewport({ scale: 1.0 })
      var pdfX = (x / rect.width) * viewport.width
      var pdfY = (y / rect.height) * viewport.height
      const pointY = parseInt(viewport.height - pdfY)
      const pointX = pdfX

      const llX = parseInt(pointX - imageX / 2) + 10
      const llY = parseInt(pointY - imageY / 2) + 10
      const urX = parseInt(pointX + imageX / 2) - 5
      const urY = parseInt(pointY + imageY / 2)
      const viewLLY = parseInt(y - this.imageY / 2)
      const viewURY = parseInt(y + this.imageY / 2)
      const viewLLX = parseInt(x - this.imageX / 2)
      const viewURX = parseInt(x + this.imageX / 2)

      if (!this.listUserSignatories?.length) return

      const newSignatories = this.listUserSignatories?.map((signatory) => {
        // Check if the signature is clicked
        const isClickedSignature =
          pointX >= signatory?.metadata?.llX &&
          pointX <= signatory?.metadata?.urX &&
          pointY >= signatory?.metadata?.llY &&
          pointY <= signatory?.metadata?.urY &&
          page === signatory?.metadata?.page
        if (isClickedSignature && signatory.metadata) {
          return { ...signatory, metadata: null, isSelected: true }
        }
        if (!signatory?.metadata && signatory.isSelected) {
          const metadata = {
            llX,
            llY,
            urX,
            urY,
            page,
            viewLLY,
            viewURY,
            viewLLX,
            viewURX,
            pointX: x,
            pointY: y
          }

          return { ...signatory, metadata }
        }

        return { ...signatory }
      })

      this.listUserSignatories = newSignatories

      const nextSignatoryIndex = this.listUserSignatories?.findIndex(
        (itemFilter) => !itemFilter?.metadata
      )

      if (!nextSignatoryIndex || nextSignatoryIndex < 0) return

      this.handleChangeSelectedSignatory(nextSignatoryIndex)

      // Re-render DOM
      this.$forceUpdate()
    },
    handleChangeSelectedSignatory (index) {
      this.listUserSignatories.forEach((signatory, i) => {
        signatory.isSelected = i === index
      })
    },
    async handleSignDocument () {
      try {
        const isValid = this.handleValidateSignDocument()
        if (!isValid) return
        this.isLoading = true
        let responseEnvelope
        let documentBase64

        // Process documentBase64 and Envelope
        if (!this.envelopeInfo) {
          const { envelopeUrl, documentBase64Data } =
            await this.handleProcessDocument()
          if (!envelopeUrl || !documentBase64Data) return

          documentBase64 = documentBase64Data
          responseEnvelope = await this.handleCreateEnvelope(envelopeUrl)

          if (!responseEnvelope || !responseEnvelope?.id) {
            this.$message({
              type: 'error',
              message: this.$t('Ký tài liệu thất bại')
            })
            return
          }
        } else {
          await this.handleUpdateSignatoryMetadata()

          responseEnvelope = this.envelopeInfo
          documentBase64 = await this.getFileAsBase64(this.urlPDF)

          if (!documentBase64) return
        }

        // const envelopSignatoryID = responseEnvelope?.envelope_signatories?.find(
        //   (item) =>
        //     item?.user_id === this.signerID ||
        //     (item?.user_id === 0 && this.isSignManual)
        // )?.id

        const isValidSigningRule =
          this.handleValidateSigningRule(responseEnvelope)

        if (!isValidSigningRule) return

        const params = this.listUserSignatories?.map((item) => {
          const responseSignatory =
            responseEnvelope?.envelope_signatories?.find((itemFind) => {
              if (item?.node_signatory_id) {
                return itemFind?.node_signatory_id === item?.node_signatory_id
              } else {
                return itemFind?.node_signatory_id === item?.id
              }
            })

          return {
            workername: this.selectedSignature?.signature_name,
            envelope_id: responseEnvelope.id,
            signatory_id: responseSignatory?.id,
            document_base64: documentBase64
          }
        })

        const response = await this.request.signingDocumentWithSignatories({
          list_data: params
        })

        if (response.status === 200) {
          const [envelopeUrl] = await this.handleUploadFilesAWS(response.data)

          await this.handleUpdateEnvelopeDocumentURL(
            responseEnvelope,
            envelopeUrl
          )

          this.$message({
            type: 'success',
            message: this.$t('Ký tài liệu thành công')
          })

          const responseFormat = 'data:application/pdf;base64,' + response.data

          this.$emit('onSuccess', responseFormat, responseEnvelope)
          this.handleClose()
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: this.$t('Ký tài liệu thất bại')
        })
      } finally {
        this.isLoading = false
      }
    },
    async getPDFPageViewer (pageNum) {
      const pageViewer = await this.documentPDFViewer.getPage(pageNum)
      return pageViewer
    },
    handleChangeImageSignature (data) {
      const base64 = data.signature_base64
      this.signatureImage = this.generateImageSrcBase64(base64)

      this.canvasSelectedClicked = false
      this.selectedSignature = data
    },
    generateImageSrcBase64 (content) {
      var prefix = 'data:image/png;base64,'

      return prefix + content
    },
    async handleCreateEnvelope (base64Document) {
      try {
        const signatories = this.handleProcessEnvelopSignatories()
        const params = {
          sign_procedure_id: this.procedureID,
          document_id: this.documentID,
          document_type: this.documentType,
          // document_url: this.do,
          envelope_name:
            this.envelopeName || this.getEnvelopeNameByType(this.documentType),
          document_url: base64Document,
          envelope_description: '',
          envelope_type: ENVELOPE_TYPE.SELF_SIGN,
          issuer: this.$user?.id,
          issuer_date: window.moment().valueOf(),
          envelope_signatories: signatories
          // expired_date: '',
        }

        const response = await this.request.createEnvelope(params)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleProcessDocument () {
      try {
        const newImageSrc = new URLSearchParams(this.urlPDF).toString()
        const formatImageSrc = newImageSrc.substring(0, newImageSrc.length - 1)
        const responseImage = await this.$rf
          .getRequest('DoctorRequest')
          .postUrlImage(formatImageSrc)
        const base64File = responseImage.data?.data || ''

        const [envelopeUrl] = await this.handleUploadFilesAWS(base64File)

        return { envelopeUrl, documentBase64Data: base64File }

        // return base64File
      } catch (error) {
        console.log(error)
      }
    },
    handleValidateSignDocument () {
      if (!this.urlPDF) return

      if (!this.selectedSignature?.signature_name || !this.signatureImage) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm thấy thông tin chữ ký')
        })
        return
      }

      if (!this.canSign) {
        const isAllSelectedSignatories = this.listUserSignatories.every(
          (item) => item?.metadata
        )

        if (!isAllSelectedSignatories) {
          this.$message({
            type: 'error',
            message: this.$t('Vui lòng chọn vị trí cho tất cả chữ ký')
          })

          return
        }

        this.$message({
          type: 'error',
          message: this.$t('Bạn không có quyền ký tài liệu')
        })

        return
      }

      return true
    },

    handleProcessEnvelopSignatories () {
      if (this.isUseSignProcedure) {
        const signatories = this.envelopeSignatories?.map((item) => {
          const metadata = this.listUserSignatories?.find(
            (itemFind) => itemFind?.id === item?.id
          )?.metadata

          const llX = metadata?.llX
          const llY = metadata?.llY
          const urX = metadata?.urX
          const urY = metadata?.urY
          const page = metadata?.page
          const viewllX = metadata?.viewLLX
          const viewllY = metadata?.viewLLY
          const viewurX = metadata?.viewURX
          const viewurY = metadata?.viewURY

          if (
            (this.signerID && item?.user_id === this.signerID) ||
            (item?.person_id && this.isPatientSign)
          ) {
            return {
              // ...item,
              node_signatory_id: item?.id,
              user_id: item?.user_id,
              person_id: item?.person_id,
              signatory_name: item?.user?.name,
              signatory_email: item?.user?.email,
              signatory_role: SIGNATORY_ROLE.signer,
              signatory_order: item?.sign_order,
              role_name: item?.role_name,
              is_required: item?.is_required,
              expired_date: item?.expired_date || undefined,
              metadata: {
                signature_id: this.selectedSignature.id,
                signature_position_rectangle: [
                  viewllX,
                  viewllY,
                  viewurX,
                  viewurY
                ].join(','),
                signature_position_rectangle_pdf: [llX, llY, urX, urY].join(
                  ','
                ),
                signature_position_page: String(page)
              }
            }
          } else {
            return {
              // ...item,
              node_signatory_id: item?.id,
              user_id: item?.user_id,
              person_id: item?.person_id,
              signatory_name: item?.user?.name,
              signatory_email: item?.user?.email,
              signatory_role: SIGNATORY_ROLE.signer,
              signatory_order: item?.sign_order,
              role_name: item?.role_name,
              is_required: item?.is_required,
              expired_date: item?.expired_date || undefined
            }
          }
        })

        return signatories
      } else {
        // TODO: manual
        return []
      }
    },
    handleCreateSignatureSuccess (data) {
      const self = this
      self.signatureImage = self.generateImageSrcBase64(data.signature_base64)
      self.selectedSignature = data
      // self.canvasSelectedClicked = true
      self.$nextTick(() => {
        const image = document.getElementById(self.imageID)
        image.style.left = self.metadata.pointX + 'px'
        image.style.top = self.metadata.pointY + 'px'
        // self.handleCheckMouseInPage()
      })

      this.refreshSignature++

      // console.log(data)
    },
    async handleUploadFilesAWS (base64) {
      if (!this.documentID) return

      const formatBase64 = 'data:application/pdf;base64,' + base64

      const file = appUtils.convertBase64tToFile(
        formatBase64,
        this.getEnvelopeNameByType(this.documentType)
      )

      const prefixPath = `resources/sign_envelopes/${this.documentType}/`

      const resultUrl = await uploadS3File.uploadHandler(
        this.documentID,
        window.moment().valueOf(),
        [file],
        1,
        prefixPath
      )

      return resultUrl?.map((item) => item?.url)
    },
    async handleUpdateEnvelopeDocumentURL (responseEnvelope, url) {
      if (!responseEnvelope?.id) return

      try {
        const request = new SignatureRequest()

        await request.updateEnvelope(responseEnvelope.id, {
          document_url: url
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getFileAsBase64 (url) {
      try {
        // Fetch the file from the URL
        const response = await fetch(url)

        // Ensure the response is ok
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        // Get the Blob from the response
        const blob = await response.blob()

        // Convert the Blob to a Base64 string
        const base64String = await this.blobToBase64(blob)
        const base64 = this.removePrefixDocument(base64String)

        return base64
      } catch (error) {
        console.error('Error fetching or converting the file:', error)
      }
    },

    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    removePrefixDocument (base64) {
      let prefix = 'data:application/pdf;base64,'
      if (base64.startsWith(prefix)) {
        return base64.slice(prefix.length)
      }
      return base64
    },
    async handleUpdateSignatoryMetadata () {
      try {
        const signatoriesMetadata = this.listUserSignatories?.map(
          (signatoryData) => {
            const signatory = this.envelopeSignatories?.find(
              (itemFind) => itemFind?.id === signatoryData?.id
            )
            const llX = signatoryData?.metadata?.llX
            const llY = signatoryData?.metadata?.llY
            const urX = signatoryData?.metadata?.urX
            const urY = signatoryData?.metadata?.urY
            const page = signatoryData?.metadata?.page
            const viewllX = signatoryData?.metadata?.viewLLX
            const viewllY = signatoryData?.metadata?.viewLLY
            const viewurX = signatoryData?.metadata?.viewURX
            const viewurY = signatoryData?.metadata?.viewURY

            return {
              signatory_metadata_id: signatory?.signatory_metadata?.id,
              signature_id: this.selectedSignature.id,
              signature_position_rectangle: [
                viewllX,
                viewllY,
                viewurX,
                viewurY
              ].join(','),
              signature_position_rectangle_pdf: [llX, llY, urX, urY].join(','),
              signature_position_page: String(page)
            }
          }
        )

        await this.request.updateListSignatoryMetadata({
          list_data: signatoriesMetadata
        })

        // const signatoryMetadata = this.currentSignatory?.signatory_metadata
        // if (!signatoryMetadata?.id) return

        // const llX = this.metadata?.llX
        // const llY = this.metadata?.llY
        // const urX = this.metadata?.urX
        // const urY = this.metadata?.urY
        // const page = this.metadata?.page
        // const viewllX = this.metadata?.viewLLX
        // const viewllY = this.metadata?.viewLLY
        // const viewurX = this.metadata?.viewURX
        // const viewurY = this.metadata?.viewURY
        // const params = {
        //   signature_id: this.selectedSignature.id,
        //   signature_position_rectangle: [
        //     viewllX,
        //     viewllY,
        //     viewurX,
        //     viewurY
        //   ].join(','),
        //   signature_position_rectangle_pdf: [llX, llY, urX, urY].join(','),
        //   signature_position_page: String(page)
        // }
      } catch (error) {
        console.log(error)
      }
    },
    openModalCreate () {
      this.$refs.ModalCreateSignature.handleOpen(this.signerID)
    },
    handleCreateSuccess (signature) {
      this.refreshSignature++
      this.selectedSignature = signature
    },
    handleValidateSigningRule (responseEnvelope) {
      // Check permission signatory
      const hasPermissionSignatory = this.envelopeSignatories?.some(
        (item) =>
          item?.user_id === this.$user?.id || item?.user_role == 'patient'
      )

      if (!hasPermissionSignatory) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })
        return
      }

      // Validate signed
      // const hasSignedEnvelope =
      //   this.currentSignatory?.status === SIGNATORY_STATUS.signed
      // if (hasSignedEnvelope) {
      //   this.$toast.open({
      //     type: 'warning',
      //     message: 'Bạn đã ký tài liệu này'
      //   })
      //   return
      // }

      // Validate signatory_order
      const isValidSignOrder = responseEnvelope?.envelope_signatories
        ?.filter(
          (item) =>
            item?.signatory_order <
              (this.currentSignatory?.signatory_order ||
                this.currentSignatory?.sign_order) &&
            item?.node_signatory_info?.sign_node_id ===
              this.currentSignatory?.node_signatory_info?.sign_node_id
        )
        .every((item) => item?.status === SIGNATORY_STATUS.signed)

      if (!isValidSignOrder) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn phải ký theo thứ tự! Vui lòng chờ đến lượt ký của bạn'
        })

        return
      }

      return true
    },
    handleClearEvent () {
      const self = this
      const container = document.getElementById(this.containerID)
      const canvases = container?.querySelectorAll('canvas')

      if (self.canvasEventHandlers) {
        const containerHandler = self.canvasEventHandlers.get(container)
        if (containerHandler) {
          container.removeEventListener('mousemove', containerHandler)
          self.canvasEventHandlers.delete(container)
        }

        canvases.forEach((item) => {
          const handler = self.canvasEventHandlers.get(item)
          if (handler) {
            item.removeEventListener('mousedown', handler)
            self.canvasEventHandlers.delete(item)
          }
        })
      }
    },
    handleProcessListUserSignatories (signatories) {
      const newSignatories = signatories?.reduce((arr, item) => {
        if (
          (item?.user_id === this.$user?.id &&
            item.status !== SIGNATORY_STATUS.signed &&
            !this.isPatientSign) ||
          (item?.person_id && this.isPatientSign)
        ) {
          if (arr?.length === 0) {
            arr.push({ ...item, isSelected: true })
          } else {
            // Chỉ lấy những signatory cùng user_id và cùng sign order
            const isEqualSignOrder = arr.every((childItem) => {
              const childOrder =
                childItem?.sign_order || childItem?.signatory_order
              const itemOrder = item?.signatory_order || item?.sign_order

              return childOrder && itemOrder && childOrder === itemOrder
            })
            if (isEqualSignOrder) {
              arr.push(item)
            }
          }
        }

        return arr
      }, [])

      this.listUserSignatories = newSignatories
    }
  }
}
</script>
    
<style lang="scss" scoped>
.vue-pdf-embed {
  margin: 0 auto;
}

.vue-pdf-embed__page {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.cs-container {
  overflow: auto;
  // pointer-events: none;
}

.pdf-container {
  width: 1000px;
  border: 1px solid #000;
  margin: 0 auto;
  position: relative;
}

::v-deep {
  .vue-pdf-embed {
    .vue-pdf-embed__page {
      box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .cs-dialog {
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
  }
}

.cs-image-pdf {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
}

.cs-sign {
  position: sticky;
  top: 0px;
  right: 20px;
  background-color: rgb(74, 74, 74);
  z-index: 100000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 8px 16px;
}

.cs-default-signature {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc820;
}

.cs-btn-container {
  display: flex;
  gap: 8px;
}
</style>