<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand d-lg-none" href="#">{{name}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" :class="type === 'services' ? 'active fw-500' : ''">
              <a
                class="nav-link"
                aria-current="page"
                href="javascript:;"
                @click="changeType('services')"
              >Danh sách dịch vụ</a>
      </li>
      <li class="nav-item" :class="type === 'category' ? 'active fw-500' : ''">
              <a
                class="nav-link"
                href="javascript:;"
                @click="changeType('category')"
              >Loại dịch vụ</a>
      </li>
    </ul>
  </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'ServiceNavbar',
  props: ['name', 'type'],
  methods: {
    changeType (type) {
      if (type === 'services') this.$router.push({path: '/admin/service'}).catch(() => {})
      if (type === 'category') this.$router.push({path: '/admin/service-category'}).catch(() => {})
    }
  }
}
</script>

<style lang="css" scoped>
.text-white {
  color: white !important
}
</style>