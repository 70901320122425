<template>
  <div
    class="modal fade"
    id="ModalCreateStats"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateStatsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalCreateStatsLabel">{{ $t('lbl_add_monitoring_cate') }}</h5>
          <button
            type="button"
            class="close mr-2 bg-pri bd-pri text-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="name" class="robo-18-400 mb-1 required">{{ $t('lbl_monitoring_cate_name') }}</label>
            <input type="text" class="form-control border" v-model="form.name" />
          </div>
          <div class="mb-3">
            <label for="name" class="robo-18-400 mb-1 required">Code (vd: huyet_ap)</label>
            <input type="text" class="form-control border" v-model="form.code" />
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <div class="input-group">
                <span class="form-group-text btn bg-blue-gray border-0 robo-16-500 txt-pri">Min</span>
                <input
                  type="number"
                  step="any"
                  class="form-control robo-16-500 txt-pri"
                  min="0"
                  v-model="form.min_threshold"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <span class="form-group-text btn bg-blue-gray border-0 robo-16-500 txt-pri">Max</span>
                <input
                  type="number"
                  step="any"
                  class="form-control robo-16-500 txt-pri"
                  min="0"
                  v-model="form.max_threshold"
                />
              </div>
            </div>
            <div class="col-12 text-center">
              <small class="text-danger">{{getThreshErr()}}</small>
            </div>
          </div>
          <!-- <div class="mb-3">
            <label
              for=""
              class="robo-18-400 mb-1"
            >Cơ sở y tế</label>
            <v-select
              v-if="clinics && clinics.data"
              class="mt-2"
              :options="clinics.data"
              label="item_label"
              placeholder="Chọn cơ sở y tế"
              :filterable="false"
              @open="onOpen"
              @close="onClose"
              v-model="clinic_selected"
              transition=""
              @search="searchClinic"
            >
              <div slot="no-options">Not found!</div>
              <template v-slot:option="item">
                <span class="font-weight-bold">{{item.name}} - {{item.phone}}</span>
              </template>
              <template #list-footer>
                <li
                  ref="load"
                  v-show="hasNextPage"
                ></li>
              </template>
            </v-select>
          </div>-->
          <div class="mb-3">
            <label for="name" class="robo-18-400 mb-1">{{ $t('lbl_unit') }}</label>
            <input type="text" class="form-control border" v-model="form.unit" />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri bd-pri text-white px-4"
            @click="onSubmit()"
          >{{ $t('lbl_add') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'ModalCreateStats',
  components: { vSelect },
  data () {
    return {
      form: {
        name: '',
        code: '',
        unit: '',
        max_threshold: '',
        min_threshold: '',
        cycle: 1,
        cycle_unit: 'ngày'
      },
      clinics: [],
      search: '',
      observer: null,
      clinic_selected: []
    }
  },
  computed: {
    hasNextPage () {
      let self = this
      if (self.clinics.total > self.clinics.count) {
        return true
      }
      return false
    }
  },
  mounted () {
    // let self = this
    // self.observer = new IntersectionObserver(self.infiniteScroll)
    // self.getClinics()
  },
  methods: {
    onSubmit () {
      let self = this
      if (!self.form.name) {
        alert(this.$t('msg_enter_monitoring_cate_name'))
        return
      } else if (!self.form.code) {
        alert(this.$t('msg_enter_monitoring_cate_code'))
        return
      }
      let data = {
        name: self.form.name,
        code: self.form.code,
        unit: self.form.unit,
        max_threshold: self.form.max_threshold,
        min_threshold: self.form.min_threshold,
        cycle: self.form.cycle,
        cycle_unit: self.form.cycle_unit,
        clinic_id: self.clinic_selected ? self.clinic_selected.id : null,
        status: 1
      }
      self.form = {
        name: '',
        code: '',
        unit: ''
      }
      self.$emit('onCreate', data)
    },
    async getClinics (num) {
      try {
        let self = this
        let params = {
          limit: num || 20,
          search: self.search,
          sort: 'created_at',
          sort_by: 'desc'
        }
        const result = await self.$rf
          .getRequest('AdminRequest')
          .getClinics(params)
        self.clinics = result.data
        self.clinics.data.map(x => {
          x.item_label = x.name + ' - ' + x.phone
        })
      } catch (e) {
        console.log(e)
      }
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var limit = this.clinics.count + 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.getClinics(limit)
      }
    },
    searchClinic (search) {
      let self = this
      if (search) {
        self.search = search
      } else {
        self.search = ''
      }
      self.getClinics()
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    getThreshErr () {
      let stats = this.form
      if (stats.min_threshold || stats.max_threshold) {
        if (
          stats.min_threshold === '' ||
          stats.max_threshold === '' ||
          isNaN(stats.min_threshold) ||
          isNaN(stats.max_threshold)
        ) {
          return this.$t('lbl_min_and_max_value_is_required')
        } else if (
          parseFloat(stats.min_threshold) > parseFloat(stats.max_threshold)
        ) {
          return this.$t('lbl_max_value_must_be_greater_than_min_value')
        } else if (
          parseFloat(stats.min_threshold) < 0 ||
          parseFloat(stats.max_threshold) <= 0
        ) {
          return this.$t('lbl_min_and_max_value_must_be_greater_than_0')
        }
      }
    }
  }
}
</script>

<style scoped>
</style>