<template>
  <div class="py-2">
    <el-form
      ref="form"
      :model="filters"
      label-position="top"
      size="medium"
      class="flex gap-4"
    >
      <el-form-item label="Tìm kiếm">
        <el-input
          placeholder="Nhập tên tài liệu"
          v-model="filters.keyword"
          style="min-width: 250px"
          clearable
          @input="debounceSearch"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-form-item>
      <el-form-item label="Ngày tạo">
        <div class="flex gap-2">
          <el-date-picker
            v-model="filters.from_date"
            type="date"
            placeholder="Từ ngày"
            clearable
            @change="$emit('filter', filters)"
          ></el-date-picker>
          <el-date-picker
            v-model="filters.to_date"
            type="date"
            placeholder="Đến ngày"
            clearable
            @change="$emit('filter', filters)"
          ></el-date-picker>
        </div>
      </el-form-item>

      <el-form-item label="Ngày ký" v-if="type === 'signed'">
        <div class="flex gap-2">
          <el-date-picker
            v-model="filters.sign_from"
            type="date"
            placeholder="Từ ngày"
            clearable
            @change="$emit('filter', filters)"
          ></el-date-picker>
          <el-date-picker
            v-model="filters.sign_to"
            type="date"
            placeholder="Đến ngày"
            clearable
            @change="$emit('filter', filters)"
          ></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="Người tạo">
        <el-select
          v-model="filters.created_by"
          filterable
          placeholder="Chọn người tạo"
          clearable
          @change="$emit('filter', filters)"
        >
          <el-option
            v-for="item in listDoctors"
            :key="item.doctor.id"
            :label="item.doctor.user.name"
            :value="item.doctor.user_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="Người ký"  v-if="type === 'signed'">
        <el-select v-model="filters.issuer_user" filterable placeholder="Chọn người ký" clearable>
          <el-option
            v-for="item in listDoctors"
            :key="item.doctor.id"
            :label="item.doctor.user.name"
            :value="item.doctor.id"
          ></el-option>
        </el-select>
      </el-form-item>-->
    </el-form>
  </div>
</template>
<script>
import { debounce } from 'lodash'

export default {
  name: 'FilterSignature',
  components: {},
  props: {
    type: {
      type: String,
      default: 'not_sign'
    },
    filters: {
      type: Object,
      default: () => ({
        created_by: null,
        from_date: null,
        to_date: null,
        sign_from: null,
        sign_to: null,
        keyword: ''
      })
    }
  },
  data () {
    return {
      listDoctors: []
    }
  },
  watch: {},
  computed: {},
  mounted () {
    // console.log('run')
    // if (this.$route.query.created_by) {
    //   this.filters.created_by = +this.$route.query.created_by
    // }
    // if (this.$route.query.keyword) {
    //   this.filters.keyword = this.$route.query.keyword
    // }
    // if (this.$route.query.from_date) {
    //   this.filters.from_date = +this.$route.query.from_date
    // }
    // if (this.$route.query.to_date) {
    //   this.filters.to_date = +this.$route.query.to_date
    // }
    // this.$emit('filter', this.filters)

    this.getClinicDoctors()
  },
  methods: {
    async getClinicDoctors () {
      try {
        const params = {
          page: 1,
          limit: 1000
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getClinicDoctor(this.$globalClinic?.id, params)
        this.listDoctors = res.data.data
      } catch (error) {
        console.log(error)
      }
    },

    debounceSearch: debounce(function () {
      this.$emit('filter', this.filters)
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-form {
  .el-form-item {
    margin-bottom: 0;
    .el-form-item__label {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 0;
    }
  }
}
.gap-4 {
  gap: 16px !important;
}
</style>
    