<template>
  <div class="cs-container">
    <el-table class="cs-table" :data="data" style="width: 100%">
      <el-table-column label="Tên tài liệu">
        <template slot-scope="scope">
          <span>{{
            scope.row.document_type &&
            getEnvelopeNameByType(scope.row.document_type)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="envelope_name" label="Tên hồ sơ"></el-table-column>
      <el-table-column prop="created_at" label="Ngày tạo" width="160">
        <template slot-scope="scope">
          <span>{{
            scope.row.created_at && formatDateTime(scope.row.created_at)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="issuer_info.name"
        label="Người tạo"
        width="160"
      ></el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <div class="flex items-center gap-3 justify-end">
            <el-button
              class="cs-button"
              @click="handleOpenFile('view', scope.row)"
              type="primary"
              plain
              size="mini"
              >Xem</el-button
            >
            <el-button
              class="cs-button"
              type="primary"
              size="mini"
              v-if="type !== 'signed'"
              @click="handleOpenFile('sign', scope.row)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
              >
                <path
                  fill="white"
                  d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                />
              </svg>

              <span style="color: white" class="ml-1">Ký</span>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <ModalSignPDF
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_ModalSignPDF`"
      :containerIDProps="`${pdfViewerID}_ContainerSignPDFID`"
      :elementIDProps="`${pdfViewerID}_SignPDFID`"
      :imageIDProps="`${pdfViewerID}_SignPDFImageID`"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_PdfViewer`"
      :containerIDProps="`${pdfViewerID}_ContainerPDFViewerID`"
      :elementIDProps="`${pdfViewerID}_PDFViewerID`"
    />
  </div>
</template>
<script>
import SignatureIcon from '../../Icon/Sidebar/SignatureIcon.vue'
import ModalSignPDF from '../ModalSignPDF.vue'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import uploadS3File from '../../../utils/uploadS3File'

export default {
  name: 'TableSignature',
  components: { SignatureIcon, ModalSignPDF, PdfViewer },
  mixins: [mixinSignPdf],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'not_sign'
    }
  },
  data () {
    return {
      pdfViewerID: null
    }
  },

  computed: {},
  mounted () {},
  methods: {
    formatDateTime (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },

    handleOpenFile (type = '', item) {
      this.pdfViewerID = item.id

      this.$nextTick(() => {
        if (type === 'view') {
          this.handleOpenPdfViewer(item)
        } else {
          this.openModalSignPDF(item)
        }
      })
    },

    async openModalSignPDF (item) {
      const modalSignRef = `${this.pdfViewerID}_ModalSignPDF`
      const pdfViewerRef = `${this.pdfViewerID}_PdfViewer`

      const envelopeInfo = await this.handleCheckSignEnvelope(
        item.document_type,
        item.document_id,
        pdfViewerRef,
        modalSignRef
      )

      return envelopeInfo
    },

    getUserByRole (role, callback) {
      let user
      let signer
      switch (role) {
        case 'dean':
          const dean = this.viewForm?.dean_info?.User
          if (!dean) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa')
          } else {
            user = dean
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          }

          break

        case 'unit_commander':
          const uintHead = this.viewForm?.unit_head_info?.User

          if (uintHead) {
            signer = uintHead
            user = signer
            // eslint-disable-next-line standard/no-callback-literal
            callback(null, signer)
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Thủ trưởng đơn vị')
          }

          break
        default:
          break
      }

      return user
    },
    async handleOpenPdfViewer (item) {
      const s3Url = await uploadS3File.getLinkVideoAWS(item?.document_url)

      const pdfViewerRef = `${this.pdfViewerID}_PdfViewer`

      this.$refs[pdfViewerRef].handleOpen(
        s3Url,
        item.document_type,
        item.document_id,
        false,
        false
      )
    },
    handleSignDocumentSuccess () {
      this.$emit('onRefresh')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.icon {
  width: 20px !important;
  height: 20px !important;
}
.action {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #20419b;
    font-weight: 500;
  }
}

.cs-container * {
  color: black;
}

.cs-table * {
  color: black;
}
::v-deep {
  .cs-table {
    thead {
      color: black !important;
    }
  }
}

.cs-button {
  height: 32px;
}
</style>
      