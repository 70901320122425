<template>
    <div class="input-group input-search-box bg-blue-gray">
        <span class="input-group-text bg-blue-gray border-0 radius-10 font-size-20 cursor-pointer" @click="onSearch()">
            <i class="fa fa-search"></i>
        </span>
        <input type="search" v-model="key_words" class="form-control bg-blue-gray" @keyup.enter="onSearch()" :placeholder="name || 'Nhập từ khoá tìm kiếm'" />
        <span class="input-group-text bg-blue-gray border-0 radius-10 cursor-pointer" :class="!key_words ? 'disabled' : ''" @click="key_words='',onSearch()">
            <i class="fa fa-times-circle"></i>
        </span>
    </div>
</template>
<script>
export default {

  name: 'SearchInput',
  props: ['search', 'name'],
  data () {
    return {
      key_words: ''
    }
  },
  mounted () {
    this.key_words = this.search
  },
  methods: {
    onSearch () {
      this.$emit('onSearch', this.key_words)
    }
  }
}
</script>
<style lang="css" scoped>
  .form-control {
    background-color: #F2F5FA !important;
  }
</style>